<template>
    <div class="footer__background">
    <img src="@/assets/images/Gotop.svg" class="gotop" @click="srcollUp"/>
    <div class="footer">
        <div class="footer__block1 container">
            <div class="footer__block1__left">
                    <div>
                        <router-link to="/" style="text-decoration: none; color: inherit;"><img src="@/assets/icons/ntsc.svg"/></router-link>
                    </div>
                    <div class="footer__block1__left__text">
                        <router-link to="/" style="text-decoration: none; color: inherit;"><p>{{ $t('aboutUs') }}</p></router-link>
                        <router-link to="/activity" style="text-decoration: none; color: inherit;"><p>{{ $t('activity') }}</p></router-link>
                        <router-link to="/publication" style="text-decoration: none; color: inherit;"><p>{{ $t('publications') }}</p></router-link>
                        <router-link to="/news" style="text-decoration: none; color: inherit;"><p>{{$t('News')}}</p></router-link>
                        <router-link to="/ecd" style="text-decoration: none; color: inherit;"><p>{{$t('ECDmini')}}</p></router-link>
                    </div>
            </div>
            <div class="footer__block1__right">
                 <div style="font-size: 16px; color:#89E4FF;" class="footer__block1__right__title"> 
                    <router-link to="/contact" style="text-decoration: none; color: inherit;"><p>{{contacts.title}}</p> </router-link>
                </div>
                <p v-html="contacts.content"></p>
            </div>
        </div>
        <div class="line"></div>
        <div class="footer__block2 container">
            <div class="footer__block2__left">
                <img src="@/assets/icons/copyright.svg"/>
                <p>Nuclear technology safety center </p>
            </div>
            <div class="footer__block2__right">
                <img src="@/assets/icons/document.svg"/>
                <a :href="$cdn + contacts.policy" target="_blank" style="text-decoration: none; color: inherit;" download><p>Политика конфиденциальности</p></a>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    data(){
        return{
            contacts:""
        }
    },
    methods: { 
        srcollUp() { 
            window.scrollTo({ 
            top: 0, 
            behavior: 'smooth' 
            }); 
        }
    },
    async created() {
    await this.$axios.get(`/contacts`)
    .then(res => {
      this.contacts = res.data.data
    })
    .catch(err => {
    })
  }
}
</script>
<style lang="scss" scoped>
.gotop{
    position: absolute;
    width:42px;
    height: 42px;
    right: 16%;
    top: -6%;
    cursor: pointer;
    transition: all 0.5s ease;
z-index: 2;
    &:hover{
        transform: scale(1.2);
        
           
        
    }
}
.line{
    border: 1px solid #BDBDBD;
}
.footer__background{
    background: #03245F;
    position: relative;
}
    .footer{
        display: flex;
        flex-direction: column;
        &__block1{
            display: flex;
            justify-content: space-between;
            padding-top: 31px;
            padding-bottom: 31px;
            p{
                cursor: pointer;
            }
             @media screen and (max-width: $tablet){
                justify-content: space-around;
            }
            @media screen and (max-width: $mobileSmall){
                justify-content: space-around;
                
            }
            &__left{
                display: flex;
                gap: 40px;
                height: 70px;
                align-items: center;
                font-size: min(max(10px, calc(0.625rem + ((1vw - 10.24px) * 0.6696))), 16px);
                text-transform: uppercase;
                p{
                    font-family: $RobotoCondenced;
                }
                @media screen and (max-width: $tablet){
                    gap: 10px;
                }
                @media screen and (max-width: $mobileSmall){
                            display: flex;
                            gap: 10px;
                            flex-direction: column;
                            align-items: center;
                            padding-right: 20px;
                            img{
                                padding-right: 36px;
                            }
                        }
                 
                &__text{
                        display: flex;
                        gap: 25px;
                        font-size:min(max(10px, calc(0.625rem + ((1vw - 3.5px) * 0.3822))), 16px);
                        color: #89E4FF;
                        @media screen and (max-width: $mobileSmall){
                            gap: 5px;
                            flex-direction: column;
                        }
                        @media screen and (max-width: $tablet){
                            gap: 14px;
                            font-size: 12px;
                        }
                        
                    
                }
            }
            &__right{
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 22%;
                padding-top: 27px;
                font-size: min(max(9px, calc(0.5625rem + ((1vw - 10.24px) * 0.558))), 14px);
                color: #F2F2F2;
                &__title{
                    a{
                    text-transform: uppercase;
                    font-size: min(max(10px, calc(0.625rem + ((1vw - 3.5px) * 0.3822))), 16px);
                    @media(max-width:$tablet){
                        font-size: 12px;
                    }
                }
                p{
                font-family: $RobotoCondenced;
                }
            }
                @media screen and (max-width: $mobileSmall){
                    padding-top: 10px;
                    font-size: 11px;
                    width: 50%;
                }
            }
        }
        &__block2{
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: $mobileSmall){
                font-size: 10px;
            }
            &__left{
                display: flex;
                font-size: min(max(9px, calc(0.5625rem + ((1vw - 10.24px) * 0.558))), 14px);
                color: #F2F2F2;
                gap: 5px;
                align-items: center;
                @media screen and (max-width: $mobileSmall){
                font-size: 10px;
            }
            }
            &__right{
                display: flex;
                font-size: min(max(9px, calc(0.5625rem + ((1vw - 10.24px) * 0.558))), 14px);
                color: #F2F2F2;
                gap: 5px;
                align-items: center;
                padding-top:15px;
                padding-bottom: 15px;
                @media screen and (max-width: $laptop){
                font-size: 11px;
            }
            }

        }
    }
</style>