import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "./i18n";
import store from './store'
import axios from 'axios' 
import VueAwesomePaginate from "vue-awesome-paginate";

const axiosInstance = axios.create({ 
    baseURL: 'https://admin.ntsc.kz/api', 
    params: { 
      lang: store.getters.getLang 
    } 
}) 


const app = createApp(App)
.use(store)
.use(i18n)
.use(VueAwesomePaginate)
.use(router)
app.config.globalProperties.$axios = { ...axiosInstance } 
app.config.globalProperties.$cdn = 'https://admin.ntsc.kz/'
app.mount('#app')

