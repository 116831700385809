<template>
  <div class="home">
  <div class="home__block1background">
    <div class="home__block1 container">
        <div class="home__block1__top ">
          <p v-html="main.title"></p>
        </div>
        <div class="home__block1__bot">
          <p v-html="main.subtitle" class="home__block1__bot__text"></p>
        </div>
    </div>
    <div class="home__block1__logo">
      <img src="@/assets/icons/sloi3.svg"/>
    </div>
    <div class="home__block1__logo1">
      <img src="@/assets/icons/sloi4.svg"/>
    </div>
  </div>
  <div class="home__block2 container">
    <p style="font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px);text-transform: uppercase; color: #00225F;">{{ $t('Directionofactivity') }} </p>
    <div class="home__block2__main">
      <div class="home__block2__main__cards" v-for="item in main.directions" :key="item">
          <div class="home__block2__main__cards__icon">
            <img :src="$cdn + item.icon" v-if="item.icon"/>
          </div>
          <p>{{ item.content }}</p>
      </div>
    </div>
  </div>
  <div class="home__block3">
      <div class="home__block3__logo"  >
        <img src="@/assets/icons/sloi.svg"/>
      </div>
      <div class="home__block3__logo1" style="opacity: 0.5;">
        <img src="@/assets/icons/sloi1.svg"/>
      </div>
      <div class="home__block3__logo2" style="opacity: 0.3;">
        <img src="@/assets/icons/sloi2.svg"/>
      </div>  
      <div class="home__block3__logo3" style="opacity: 0.5;" >
        <img src="@/assets/icons/sloi5.svg"/>
      </div>
      <div class="home__block3__top container">
        <div class="home__block3__top__title">  
          <p>{{ main.missions?.title}}</p>
        </div>
        <div v-html="main.missions?.content" class="home__block3__top__text"> </div>
      </div>
      <div class="home__block3__bot container">
        <div class="home__block3__bot__title">
          <p>{{ $t('Founders') }}</p>
        </div>
        <div class="home__block3__bot__cards">
          <swiper 
              :slidesPerView="2" 
              :spaceBetween="140" 
              :lazy="true" :loop="true" 
              :autoplay="{
                      delay: 1,
                    }"
                    :speed="5000"
              :breakpoints="{
                      1000: {
                        slidesPerView: 2
                      },
                      767: {
                        slidesPerView: 1
                      },
                      350: {
                        slidesPerView: 1
                      }
                    }"  
              > 
              <swiper-slide v-for="(item) in main.founders" :key="item" class="swiperBlock1"> 
                  <img :src="$cdn + item.image_link"/>
                  <p>{{ item.content }}</p>
              </swiper-slide> 
          </swiper>
        </div>
      </div>
  </div>
  <div class="home__block4 container">
    <div class="home__block4__top">
      <router-link to="/news" style="color: inherit; text-decoration: none;"><p>{{ $t('News') }}</p></router-link>
      <div class="home__block4__top__right">
        <img src="@/assets/icons/arrow.svg" style="rotate: 180deg;" class="arrow1"/>
        <img src="@/assets/icons/arrow.svg" class="arrow2"/>       
      </div>
    </div>
    <div class="home__block4__bot">
      <swiper 
                    :slidesPerView="4" 
                    :spaceBetween="20" 
                    :lazy="true" :loop="false" 
                    :navigation="{ 
                        prevEl: '.arrow1', 
                        nextEl: '.arrow2', 
                        disabledClass: 'disabled' 
                    }"
                    
                    
                    :breakpoints="{
                      1800:{
                        slidesPerView: 4
                      },
                      1000:{
                        slidesPerView: 3
                      },
                      768: {
                        slidesPerView: 2
                      },
                      350: {
                        slidesPerView: 1
                      }
                    }"
                    > 
                    
                    <swiper-slide  v-for="(item) in main.news" :key="item" class="swiperBlock2"> 
                        <NewsCard :newsValue="item"/> 
                    </swiper-slide> 
                </swiper>
</div>
  </div>
</div>
</template>
<script>
import { Swiper, SwiperSlide,} from "swiper/vue"; 
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import NewsCard from "./NewsCard.vue";
import SwiperCore, { A11y, Autoplay } from "swiper"; 
import { Navigation, Pagination } from 'swiper'; 
import "swiper/swiper.min.css"; 
import news from "@/data/news.json"
SwiperCore.use([Navigation,Pagination, A11y, Autoplay])

export default {
  components: { Swiper, SwiperSlide, NewsCard},
  data() {
    return {
      news: news.news,
      main: "",
      block1: [
        {
        img: "img1",
        title: this.$t('Founders__1')
        },
        {
        img: "img2",
        title: this.$t('Founders__2')
        },
        {
        img: "img2",
        title: this.$t('Founders__2')
        },
        {
        img: "img2",
        title: this.$t('Founders__2')
        },

      ],
      block2: [
        {
          icon: "icon1",
          title: "Научно-техническая поддержка Комитета атомного и энергетического контроля и надзора Министерства энергетики Республики Казахстан (КАЭКН МЭ РК);"
        },
        {
          icon: "icon2",
          title: "Управление проектами в области ядерной безопасности и нераспространения; проектированием объектов использования атомной энергии; выводом из эксплуатации объектов использования атомной энергии;"
        },
        {
          icon: "icon3",
          title: "Оценка и проведение анализа безопасности объектов и технологий, связанных с использованием атомной энергии;"
        },
        {
          icon: "icon4",
          title: "Экспертиза проектов и технической документации в области использования атомной энергии;"
        },
        {
          icon: "icon5",
          title: "Разработка, экспертиза и научно-техническое обоснование правовой и нормативной документации в области мирного использования атомной энергии;"
        },
        {
          icon: "icon6",
          title: "Аналитическая поддержка безопасности действующих и будущих реакторов и других ядерных объектов Республики Казахстан;"
        },
        {
          icon: "icon7",
          title: "Научно-техническое сопровождение работ по физике и безопасности реакторов, радиационному материаловедению (проведение расчетно-теоретических обоснований), ядерно-топливному циклу;"
        },
        {
          icon: "icon8",
          title: "Международное сотрудничество в области безопасности ядерной и термоядерной энергетики, физики реакторов и радиационного материаловедения, радиоэкологии, новых конверсионных и ядерных технологий, экспортного контроля и нераспространения, включая обмен информацией и персоналом;"
        },
        {
          icon: "icon9", 
          title: "Обучение и тренинги в области нераспространения и ядерной безопасности, включая организацию международных конференций и семинаров."
        },
      ]
    }
  },
  async created() {
    await this.$axios.get(`/main`)
    .then(res => {
      this.main = res.data.data
    })
    .catch(err => {
    })
  }
}
</script>
<style lang="scss" scoped>

    .disabled{
      opacity: 0.5;
    }
    .home{
      &__block1background{
      background: #03245F;
      position: relative;
      padding-bottom: 40px;
      height: 654px;
      overflow: hidden;
      @media screen and (max-width: $mobile){
        height: 500px;
      }
      @media screen and (max-width: $mobileSmall) {
        height: fit-content;
        margin-top: 37px;
      }
      }
      &__block1{
      display: flex;
      flex-direction: column;
      overflow: hidden;
      justify-content: space-between;
      margin-top: 20px;
      @media screen and (max-width: $mobile) and (min-width: $mobileSmall){
        margin-top:20px;
      }
      @media screen and (max-width: $mobileSmall) {
        margin-top:80px;
      }
      &__logo{
        position: absolute;
        top: -18%;
        left: -9px;
        @media screen and (max-width: $mobileSmall) {
          img {
            width: 62%;
          }
        }
      }
      &__top{
        display: flex;
        flex-direction: column;
        font-size: min(max(20px, calc(1.25rem + ((1vw - 3.5px) * 1.2739))), 40px);
        text-transform: uppercase;
        padding-top: 226px;
        font-family: $Arsenal;
        @media screen and (max-width: $mobileSmall) {
          padding-top: 18px;
        }
      }
      &__logo1{
        position: absolute;
        left: -9.86%;
        right: 9.85%;
        bottom: -21%;
        @media screen and (max-width: $tablet) and (min-width: $mobile){
          left:-15%;
        }
        @media screen and (max-width: $mobile) and (min-width: $mobileSmall){
          img{
            display: none;
          }
        }
        @media screen and (max-width: $mobileSmall) {
          img {
            width: 62%;
            display: none;
          }
        }
      }
      &__bot{
        width: 50.66%;
        font-size:  min(max(13px, calc(0.8125rem + ((1vw - 3.5px) * 0.5096))), 21px);
        color: #FFFFFF;
        display: flex;
        justify-content: end;
        margin-left: auto;
        padding-top: 152px;
        &__text{
          font-family: $Arsenal !important;
        }
        @media screen and (max-width: $tablet) and (min-width: $mobile){
          padding-top: 40px;
          width: 60%;
        }
        @media screen and (max-width: $mobile) and (min-width: $mobileSmall){
          width: 100%;
          padding: 20px 0px;
        }
        @media screen and (max-width: $mobileSmall) {
          padding: 12px 0px;
          width: 100%;
          
        }
      }
    }
    &__block2{
      padding-top: 122px;
      display: flex;
      flex-direction: column;
      gap: 26px;
      p{
        font-family: $RobotoCondenced;
      }
      @media screen and (max-width: $mobileSmall){
        padding-top: 60px;
      }
      
      &__main{
        display: flex;
        flex-wrap: wrap;   
        align-items: stretch;     
        gap: 20px;
        @media screen and (max-width:$mobileSmall){
        width:100%;
      }
        &__cards{
          display: flex;
          width: 31%;
          background: #03245F;
          gap: 10px;
          height: 270px;
          padding: 18px 13px;
          box-sizing: border-box;
          box-shadow: 0px 0px 14px rgba(0, 174, 255, 0.4);
         
          p{
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: min(max(10px, calc(0.625rem + ((1vw - 10.24px) * 0.6696))), 16px);
            color: #FFFFFF;
            font-family: $Roboto;
          }
          @media screen and (max-width: $tablet) and (min-width: $mobile){
            width: 48% ;
          }
          @media screen and (max-width: $mobile) and (min-width: $mobileSmall){
            width: 47%;
          }
          @media screen and (max-width: $mobileSmall) {
            gap: 0px;
            width: 100%;           
            flex-direction: column;  
            height: 180px;   
            padding-bottom: 20px;                
          }
        }
      }
    }
    &__block3{
      display: flex;
      flex-direction: column;
      gap: 208px;
      position: relative;
      padding-bottom: 149px;
      @media screen and (max-width: $tablet) and (min-width: $mobile){
        gap:80px;
        padding-bottom: 80px;
      }
      @media screen and (max-width: $mobile) and (min-width: $mobileSmall){
        gap: 100px;
      }
      @media screen and (max-width: $mobileSmall) {
        gap: 100px;
        padding-bottom: 80px;
      }
      &__logo{
        position: absolute;
        left: 0%;
        right: 0%;
        top: 1%;
        bottom: 0%;
        @media screen and (max-width: $laptop) and (min-width: $tablet){
          display: none;
        }
        @media screen and (max-width: $tablet) and (min-width: $mobile){
          display: none;
        }
        @media screen and (max-width: $mobile) and (min-width: $mobileSmall){
          img{
            width: 30%;
            top: 0%;
          }
        }
        @media screen and (max-width: $mobileSmall){
          img{
          width: 52%;
          }
        }
      }
      &__logo1{
        position: absolute;
        left: 0%;
        right: 0%;
        top: 60%;
        bottom: 0%;
        @media screen and (max-width: $laptop) and (min-width: $tablet){
          display: none;
        }
        @media screen and (max-width: $tablet) and (min-width: $mobile){
          display: none;
        }
        @media screen and (max-width: $mobile) and (min-width: $mobileSmall){
          display: none;
          top: 49%;
          img{
            width: 20%;
          }
        }
        @media screen and (max-width: $mobileSmall){
          display: none;
        }
      }
      &__logo2{
        position: absolute;
        left: 53%;
        right: 0%;
        top: 15%;
        bottom: 0%;
        @media screen and (max-width: $tablet) and (min-width: $mobile){
          display: none;
        }
        @media screen and (max-width: $mobileSmall){
          display: none;
        }
        @media screen and (max-width: $mobile) and (min-width: $mobileSmall){
          top: 35%;
        }
      }
      &__logo3 {
        position: absolute;
        left: 83%;
        right: 0%;
        top: 65%;
        bottom: 0%;
        z-index: 3;
        @media(max-width:1500px){
          display: none;
        }
        @media screen and (max-width: $tablet) and (min-width: $mobile){
          display: none;
        }
        @media screen and (max-width: $mobile) and (min-width: $mobileSmall){
          top: 70%;
          display: none;
        }
        @media screen and (max-width: $mobileSmall){
          left: 60%;
          top: 56%;
          img{
          width: 200%;
          
          }
        }
      }
      &__top{
        display: flex;
        flex-direction: column;
        gap:26px;
        padding-top: 159px;
        font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px);
        color: #00225F;
        &__title{
          text-transform: uppercase;
        }
        p{
          font-family: $RobotoCondenced;
        }
        @media screen and (max-width: $tablet) and (min-width: $mobile){
          padding-top: 50px;
        }
        &__text{
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 52%;
          p{
            font-family: $Roboto;
          }
          @media screen and (max-width: $tablet) and (min-width: $mobile){
            width: 100%;
          }
          @media screen and (max-width: $mobile) and (min-width: $mobileSmall){
            width: 100%;;
          }
          @media screen and (max-width: $mobileSmall){
            width: 100%;
          }
        }
      }
      &__bot{
        display: flex;
        flex-direction: column;
        gap: 26px;
        &__title{
          font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px);
          color: #00225F;
          text-transform: uppercase;
          p{
            font-family: $RobotoCondenced;
          }
        }
        .swiperBlock1{
          display: flex;
          gap:10px;
          align-items: center;
          font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px);
          color: #00225F;
        }
      }
    }
    &__block4{
        display: flex;
        flex-direction: column;
        gap: 28px;
        &__top{
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px);
          color: #03245F;
          text-transform: uppercase;
          p{
            font-family: $RobotoCondenced;
          }
          &__right{
            display: flex;
            gap:25px;
          }
        }
        &__bot{
          padding-bottom: 118px;
        }
    }
  }

</style>