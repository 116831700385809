import { createStore } from 'vuex'

export default createStore({
  state: {
    lang: localStorage.getItem("lang") || "ru",
    openExpertModal: false,
    openSearchModal: false,
    selectedItem: {},
    blockId : ''
  },
  getters: {
    getLang: (state) => state.lang,
  },
  mutations: {
    SET_LANGUAGE(state, lang) { 
      localStorage.setItem("lang", lang); 
      state.lang = lang; 
    },
    setSelectedItem(state, item) {
      state.selectedItem = item;
    }   
  },
  actions: {
  },
  modules: {
  }
})
