<template>
    <div class="news" :class="customClass" @click="$router.push('/news/' + newsValue.id)">
            <div class="news__image" >
                <img :src="$cdn + newsValue.image"/>
            </div>
            <div class="news__title">
                <p>{{ newsValue.title }}</p>
            </div>
            <div class="news__desc">
                <p v-html="newsValue.content"></p>
            </div>
            <div class="news__link" @click="$router.push('/news/' + newsValue.id)">
                <p>{{ $t('more')}}</p>
            </div>
    </div>
</template>
<script>
import news from "@/data/news.json"
export default {
    data(){
        return{
            news :news.news
        }
    },
    props: ['newsValue', 'customClass']
}
</script>
<style lang="scss" scoped>
.newsView {
    .news__link, .news__desc, .news__title {
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 30px ;
    }
    .news__image{
        padding-bottom: 15px;
    }
    .news__title{
        padding-bottom: 10px;
    }
    .news__desc{
        padding-bottom: 10px;
    }
}
    .news{
        display: flex;
        flex-direction: column;
        &__image{
            padding-bottom: 15px; 
            img{
            width: 100%;
            }
            @media screen and (max-width: $mobileSmall) {
          img {
            width: 100%;
          }
        }      
        }
        &__title{
            font-size: min(max(9px, calc(0.5625rem + ((1vw - 10.24px) * 0.8929))), 17px);
            color: #00225F;
            padding-bottom: 10px;
            @media screen and (max-width: $tablet){
                font-size: 15px;
            }
            p {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
        &__desc{
            font-size:  min(max(8px, calc(0.5rem + ((1vw - 10.24px) * 0.6696))), 14px);
            color: #828282;
            padding-bottom: 10px;
            line-height: normal;
            p {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
        &__link{
            font-size: min(max(8px, calc(0.5rem + ((1vw - 10.24px) * 0.6696))), 14px);
            text-decoration: underline;
            color: #03245F;
            p{
                cursor: pointer;
            }
        }
    }
</style>