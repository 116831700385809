import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AllResultsViewVue from '@/views/AllResultsView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/allresults',
    name: 'allresults',
    component: AllResultsViewVue
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('@/views/ActivityView.vue')
  },
  {
    path: '/ecd',
    name: 'ecd',
    component: () => import('@/views/ECDView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/ecd/interconvention',
    name: 'interconvention',
    component: () => import('@/views/InternationalAgreementsView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/ecd/interconvention/:slug',
    name: 'interconventionSlug',
    component: () => import('@/views/AboutIntConventionView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/ecd/literature',
    name: 'literature',
    component: () => import('@/views/LiteratureView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/ecd/experience',
    name: 'experience',
    component: () => import('@/views/ExpView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/ecd/experience/:slug',
    name: 'mainslug',
    component: () => import('@/views/AboutExpView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/ecd/intermode/:slug',
    name: 'slug',
    component: () => import('@/views/AboutModeView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/ecd/interorganization',
    name: 'interorganization',
    component: () => import('@/views/OrganizationsView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/ecd/interorganization/:slug',
    name: 'organizationSlug',
    component: () => import('@/views/AboutOrganizationView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/ecd/regional',
    name: 'regional',
    component: () => import('@/views/RegionalView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/ecd/regional/:slug',
    name: 'regionalSlug',
    component: () => import('@/views/AboutRegionalView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/ecd/lawrk',
    name: 'lawrk',
    component: () => import('@/views/LegislationView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/ecd/lawrk/:slug',
    name: 'lawrkSlug',
    component: () => import('@/views/AboutLegislationView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/ecd/govermentrk',
    name: 'govermentrk',
    component: () => import('@/views/OrgansView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/ecd/govermentrk/:slug',
    name: 'organsSlug',
    component: () => import('@/views/AboutOrgansView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/ecd/intermode',
    name: 'intermode',
    component: () => import('@/views/ModeView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/ecd/eduprogramms',
    name: 'eduprogramms',
    component: () => import('@/views/LearningView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/ecd/eduprogramms/:slug',
    name: 'eduprogrammsSlug',
    component: () => import('@/views/AboutLearningView.vue'),
    meta: {
      isHeader: true,
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/ContactView.vue')
  },
  {
    path: '/activity/expertises',
    name: 'expertises',
    component: () => import('@/views/ExpertiseView.vue')
  },
  { 
    path: '/:catchAll(.*)', 
    name: "404", 
    component: () => import("../views/NotFound.vue"), 
    meta: {
      isFooter: true,
    }
  },
  {
    path: '/activity/project-mntc',
    name: 'project-mntc',
    component: () => import('@/views/ProjectsMNTCView.vue')
  },
  {
    path: '/activity/development-npa',
    name: 'development-npa',
    component: () => import('@/views/DevelopmentView.vue')
  },
  {
    path: '/activity/courses',
    name: 'courses',
    component: () => import('@/views/CourcesView.vue')
  },
  
  {
    path: '/activity/cources/workshop',
    name: 'workshop',
    component: () => import('@/views/SeminarView.vue'),
    
  },
  {
    path: '/activity/cources/control',
    name: 'control',
    component: () => import('@/views/ExportView.vue'),
    scrollBehavior: null
  },
  {
    path: '/activity/cources/identification',
    name: 'identification',
    component: () => import('@/views/IdentificationView.vue'),
    scrollBehavior: null
  },
  {
    path: '/activity/security',
    name: 'security',
    component: () => import('@/views/SecurityView.vue')
  },
  {
    path: '/activity/project-bn350',
    name: 'project-bn350',
    component: () => import('@/views/BN350View.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/NewsView.vue')
  },
  {
    path: '/news/:slug',
    name: 'newsslug',
    component: () => import('@/views/NewsCardView.vue')
  },
  
  {
    path: '/publication',
    name: 'publication',
    component: () => import('@/views/PublicationView.vue')
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {

    if (to.hash) {
      const el = document.querySelector(to.hash);
      if (el) {
        setTimeout(() => {
          const scrollTop = el.getBoundingClientRect().top + window.pageYOffset - 0;
          window.scrollTo({
            top: scrollTop,
            behavior: 'smooth'
          });
        }, 0);
        return false;
      }
    } else {
      if (savedPosition) {
        return savedPosition;
      } else {
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }, 0);
      }
    }
  }
});

export default router
