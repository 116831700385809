<template>
    <div class="searchmodal">
      <div class="searchmodal__title">
        <p class="searchmodal__title__main">{{$t('results')}}</p>
        <p class="searchmodal__title__right" v-if="dates && dates.length" style="cursor: pointer; color: rgb(175, 39, 57); text-decoration: underline;" @click="$emit('go-result')">Перейти ко всем резуьтатам</p>
      </div>
      <div class="line"></div>
      <div v-if="dates && dates.length">
        <div v-for="item in dates.slice(0, 5)" :key="item" class="searchmodal__top">
          <div class="results" v-if="item.path">
            <p @click.stop="$router.push('/' + item.path), $store.state.blockId = item.block_id" style="width: 70%; cursor: pointer;" v-if="item.title">{{ item.title }}</p>
            <p @click.stop="$router.push('/' + item.path), $store.state.blockId = item.block_id" style="text-decoration: underline; color: blue; font-size: 15px; cursor: pointer;">{{$t('ftl')}}</p>
          </div>
          <div v-else class="results">
            <p @click.stop="$router.push('/'),  $store.state.blockId = item.block_id" style="width: 70%; cursor: pointer;" v-if="item.title">{{ item.title }}</p>
            <p @click.stop="$router.push('/'),  $store.state.blockId = item.block_id" style="text-decoration: underline; color: blue; font-size: 15px; cursor: pointer;">{{$t('ftl')}}</p>
          </div>
          <div class="line"></div>
        </div>
      </div>
      <div v-else>
        <p class="error">{{$t('nomatches')}}</p>
      </div>
    </div>
  </template>
<script>
export default {
    props: ['dates'],
}
</script>
<style lang="scss" scoped>
.error{
    padding-left: 5px;
}
.line{
    border: 1px solid #BDBDBD;
}
.results{
    display: flex;
    gap: 8px;
    align-items: center;
    padding-left: 5px;

}
    .searchmodal{
        border: 1px solid var(--gray-4, #BDBDBD);
        position: absolute;
        top:90%;
        right: -2%;
        width: 530px;
        height: 185px;
        background: white;
        z-index: 99;
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        gap: 5px;
        &__title{
            padding: 3px 5px 0px 5px;
            font-size: 16px;
            display: flex;
            justify-content: space-between;
        }
        &__top{
            display: flex;
            flex-direction: column;
            gap: 5px;
            p{
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

    }
</style>