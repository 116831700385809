<template>
  <!-- {{ $route.path == '/acitvy' }} -->
    <div class="desctop">
      <div class="headerbackground">
        <div class="header">
            <div class="header__block1" @click="$store.state.openSearchModal = false">
              <div class="container">
                <p @click="getLang(languages[0])" :class="{activeLang: $store.state.lang == 'ru'}">Русский</p>
                <p @click="getLang(languages[1])" :class="{activeLang: $store.state.lang == 'kz'}">Қазақша</p>
                <p @click="getLang(languages[2])" :class="{activeLang: $store.state.lang == 'en'}">English</p>
              </div>
            </div>
            <div class="header__block2 container">
                <div class="header__block2__left">
                    <div>
                    <router-link to="/"><img src="@/assets/icons/ntsc.svg"/></router-link>
                </div>
                <div class="header__block2__left__text" @click="$store.state.openSearchModal = false">
                  <router-link to="/" style="text-decoration: none; color: inherit;"><p :class="{activeClass:$route.path == '/'}">{{ $t('aboutUs') }}</p></router-link>
                  <div class="header__block2__left__text__activity" >
                    <router-link to="/activity" style="text-decoration: none; color: inherit;"><p :class="{activeClass:$route.path == '/activity'}">{{ $t('activity') }}</p></router-link>
                    <div class="showActivityblock" data-aos="fade-down-right" data-aos-duration="1000">
                      <router-link to="/activity/security" style="text-decoration: none; color: inherit;"><p>{{ $t('nucklear') }}</p></router-link>
                      <router-link to="/activity/expertises" style="text-decoration: none; color: inherit;"><p>{{ $t('expertises') }}</p></router-link>
                      <router-link to="/activity/development-npa" style="text-decoration: none; color: inherit;"><p>{{ $t('npa') }}</p></router-link>
                      <router-link to="/activity/project-bn350" style="text-decoration: none; color: inherit;"><p>{{ $t('bn') }}</p></router-link>
                      <router-link to="/activity/project-mntc" style="text-decoration: none; color: inherit;"><p>{{$t('mntc') }}</p></router-link>
                      <router-link to="/activity/courses" style="text-decoration: none; color: inherit;"><p>{{ $t('courses') }}</p></router-link>
                    </div>
                  </div>
                  <router-link to="/publication" style="text-decoration: none; color: inherit;"><p :class="{activeClass:$route.path == '/publication'}">{{ $t('publications') }}</p></router-link>
                </div>
                </div>
                <div class="header__block2__right">
                  <router-link to="/ecd" style="text-decoration: none; color: inherit;"><button :class="{ecdVisivle: !ecdVisivle}" class="ecd" @click="$store.state.openSearchModal = false">{{ $t('ECD') }}</button></router-link>
                  <input  @click="$store.state.openSearchModal = true" @focus="ecdVisivle = false" v-model="searchQuery" type="search" @focusout="focusOut" class="search" @keydown.enter="searchODIT"/>
                    <!-- <SearchModal v-if="$store.state.openSearchModal == true && !ecdVisivle" :dates="searchResults" @go-result="goResult()"/> -->
                </div>
            </div>
        </div>
    </div>
  </div>
    <div class="mobile"> 
        <div class="headerMobileBack"> 
            <div class="headerMobile container"> 
              <router-link to="/"><img src="@/assets/icons/ntsc.svg"/></router-link>
                <div class="headerMobile__top">
                <router-link to="/ecd" style="text-decoration: none; color: inherit;"><button style="font-size: 10px;">{{ $t('ecdmobile') }}</button></router-link>
                <div @click="burgerMenuActive = !burgerMenuActive" :class="{ 'burger-active': burgerMenuActive }" class="header__burger"> 
                    <div> 
                        <span></span> 
                        <span></span> 
                        <span></span> 
                    </div> 
              </div>
            </div>
            </div> 
        <div class="animated fadeIn"> 
            <div v-if="burgerMenuActive" class="headerMobileContent animated fadeInLogin">  
                    <div class="line"></div>           
                    <router-link to="/" style="text-decoration: none; color: inherit;"><p>{{ $t('aboutUs') }}</p></router-link>
                    <div class="line"></div>
                    <router-link to="/activity" style="text-decoration: none; color: inherit;"><p>{{ $t('activity') }}</p></router-link>
                    <div class="line"></div>
                    <router-link to="/publication" style="text-decoration: none; color: inherit;"><P>{{ $t('publications') }}</P></router-link>
                    <div class="line"></div>
                    <div class="languages">
                        <img  @click="getLang(languages[0])" src="@/assets/icons/ru.svg"/>
                        <img  @click="getLang(languages[1])" src="@/assets/icons/kz.svg"/>
                        <img  @click="getLang(languages[2])" src="@/assets/icons/en.svg"/>
                    </div>              
            </div> 
        </div> 
        </div> 
    </div>
</template>
<script>
import { mapMutations } from 'vuex';
import axios from 'axios';
import SearchModal from '../SearchModal.vue';
export default {
  components:{
    SearchModal
  },
    data() {
        return {
            burgerMenuActive: false,
            ecdVisivle: true,
            languages: [ 
                { id: 1, value: "ru" }, 
                { id: 1, value: "kz" }, 
                { id: 1, value: "en" } 
            ],    
            searchQuery: "",
            searchResults: []
        }
    },
    methods: {
        ...mapMutations(["SET_LANGUAGE"]), 
        goResult() {
          localStorage.setItem('searchResult', JSON.stringify(this.searchResults))
          this.$router.push({ name: 'allresults', query: { search: this.searchQuery } })
        },
        getLang(option) { 
          this.$i18n.locale = option; 
          this.SET_LANGUAGE(option.value); 
          this.$forceUpdate(); 
          location.reload(); 
        },
        focusOut() {
          setTimeout(() => {
            this.ecdVisivle = true,
            this.searchQuery = "",
            this.searchResults = []
          }, 300);
        }, 
      searchODIT() {
        const apiUrl = `https://admin.ntsc.kz/api/search/${encodeURIComponent(this.searchQuery)}`;
        axios.get(apiUrl)
          .then(response => {
            // Сохраните результаты поиска в searchResults
            this.searchResults = response.data.data;
            this.goResult()
          })
          .catch(error => {
            // Обработайте возможные ошибки
            console.error(error);
          });
      }
    },
    watch: {
    $route() {
      this.burgerMenuActive = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.activeSearch{
  display: none;
}
.activeClass{
  color:#00AEFF;
}
.activeLang{
  color: #1A1A1A;
}
.languages{
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 10px;
    img{
        width: 20px;
        height: 20px;
        object-fit: cover;
        border-radius: 50%; 
        overflow: hidden;
    }
}
.headerbackground{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 12;
}
    .header{
        display: flex;
        flex-direction: column;
        gap: 9px;
        &__block1{
          background: #FAFAFA;
            
            .container{
              display: flex;
            gap: 15px;
            font-size: 15px;
            color:#828282;
            justify-content: end;
            padding: 6px 0px;
            }
            p{
              cursor: pointer;
            }
        }
        &__block2{
            display: flex;
            justify-content: space-between;
            font-family: $RobotoCondenced;
            &__left{
                display: flex;
                gap: 20px;
                &__text{
                    display: flex;
                    gap: 35px;
                    color: #00225F;
                    font-size: 16px;
                    align-items: center;
                    padding-bottom: 6px;
                    text-transform: uppercase;
                    p{
                      cursor: pointer;
                      font-family: $RobotoCondenced;
                    }
                    &__activity{   
                      position: relative; 
                      display: flex;  
                      
                        &:hover {
                          .showActivityblock {
                            transition: all 0.5s ease;
                            position: absolute;
                            display: flex;
                            flex-direction: column;
                            top: 100%;
                            gap:16px;
                            width: 140px;
                            padding-top: 12px;
                            padding-right: 11px;
                            padding-left: 14px;
                            padding-bottom: 19px;
                            left:-27%;
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                            background: white;
                            font-size: 12px;
                            z-index: 999;
                            color: #00225F;                         
                            p{
                              font-family: $RobotoCondenced;
                              &:hover{
                                color: #00AEFF;
                              }
                            }
                          }
                        }    
                    }  
                    .showActivityblock{
                        display: none;
                      }              
                }
            }
            &__right{
                display: flex;
                gap: 32px;
                box-sizing: border-box;
                align-items: center;
                 font-family: $RobotoCondenced;
                button{
                    cursor: pointer;
                    height: 43px;
                    background: #00225F;
                    border-radius: 170px;
                    width: 100%;
                    font-weight: 400;
                    font-size: 16px;
                    // white-space: nowrap;
                    color: #FFFFFF;
                    overflow: hidden;
                    text-transform: uppercase;
                    transition: all 0.5s ease;
                    font-family: $RobotoCondenced;
                    border: none;
                }
            }
        }
    }


.header__burger { 
    display: block; 
    width: 30px; 
    height: 18px; 
    position: relative; 
    cursor: pointer; 
    z-index: 9; 
    & span { 
      transition: all .3s ease 0s; 
      top: calc(50% - 1px); 
      left: 0; 
      position: absolute; 
      width: 100%; 
      height: 2px; 
      background-color: #00225F; 
 
      &:first-child { 
        top: 0; 
      } 
 
      &:last-child { 
        top: auto; 
        bottom: 0; 
      } 
    } 
  } 
  .burger-active { 
    span { 
      transform: scale(0); 
 
      &:first-child { 
        transform: rotate(-45deg); 
        top: calc(50% - 1px); 
      } 
 
      &:last-child { 
        transform: rotate(45deg); 
        bottom: calc(50% - 1px); 
      } 
    } 
  } 
  // .header__right-phone, .header__right-account, .header-left { 
  //   display: none; 
  // } 
 
  // navigation 
 
  .header:last-child{ 
    position: sticky; 
  } 
  .mobile-navbar a:hover{ 
    color: #fff; 
  }
.headerMobileBack { 
  position: fixed;
  padding: 8px 0; 
  top: 0; 
  width: 100%; 
  z-index: 100; 
  background-color: white; 
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
} 
.headerMobile { 
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    
    button{
      background: #00225F;
      border-radius: 170px;
      color: white;
      font-size: 13px;
      text-transform: uppercase;
      height: 42px;
      padding: 12px 29px;
    }
    img{
        width: 60%;
        
    }
    &__top{
      display: flex;
      align-items: center;
      gap: 20px;
      button{
        border: none;
      }
    }
}

.line{
    border: 1px solid #E5E8ED;
    width: 99%;
}
.headerMobileContent { 
    display: flex; 
    flex-direction: column;
    align-items: end;
    position: fixed; 
    background: white; 
    width: 50%;
    margin-top:8px;
    font-size: 18px;
    font-weight: 600;
    color: #00225F;
    gap: 5px;
    padding-bottom: 5px;
    p{
        padding-right: 9px;
        font-family: $Arsenal;
    }
    @media screen and (max-width: $tablet){
      width: 38%;
      align-items: center;
      font-size: 22px;
    }
    @media screen and (max-width: 767px){
          width: 47%;
          align-items: end;
          font-size: 18px;
        }
} 
 
 
.animated { 
  animation-duration: 1s; 
  animation-fill-mode: both; 
} 
.fadeIn { 
  animation-name: fadeIn; 
} 
.fadeInLogin { 
  animation-name: fadeInLogin; 
} 
@keyframes fadeIn { 
  from { 
    opacity: 0; 
  } 
  to { 
    opacity: 1; 
  } 
} 
@keyframes fadeInLogin { 
  from { 
    opacity: 0; 
    right: -20%; 
  } 
  to { 
    opacity: 1; 
    right: 0%; 
  } 
}
.search { 
    position: relative; 
    width: 32px; 
    height: 32px; 
    border-radius: 50%; 
    // padding: 10px; 
    color: transparent; 
    cursor: pointer; 
    outline: none; 
    font-size: 15px; 
    background: transparent; 
    border: 1px solid transparent; 
    background-image: url('@/assets/icons/find.png') ; 
    overflow: hidden; 
    background-repeat: no-repeat; 
    background-size: 22px; 
    background-position: 45% 50%; 
} 
.search:hover { 
    border: 1px solid black; 
} 
.search:focus { 
    width: 470px; 
    cursor: text; 
    padding-left: 40px; 
    color: black !important; 
    // outline: 1px soild #993300; 
    // filter: brightness(0) saturate(100%) invert(61%) sepia(35%) saturate(5196%) hue-rotate(149deg) brightness(91%) contrast(101%);  
    border: 1px solid black !important; 
    background: url('@/assets/icons/find.png'); 
    background-repeat: no-repeat; 
    background-size: 22px; 
    border-radius: 25px; 
    background-position: 10px 50%; 
}
.ecdVisivle {
  opacity: 0;
  overflow: hidden;
  transition: all 0.5s ease;
}

</style>