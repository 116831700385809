<template>
    <div class="allresults container">
        <div class="allresults__block">
            <p class="allresults__block__title">Результаты поиска по: <span style="color: rgb(86, 182, 83);">"{{ this.$route.query.search }}"</span></p>
            <p class="allresults__block__count">Количество результатов <span style="color: rgb(86, 182, 83);">({{ allDates.length }})</span></p>
            <div class="allresults__block__res">
                <div class="allresults__block__res__in" v-for="item in allDates" :key="item">
                    <div class="results" v-if="item.path">
                        <p @click.stop="$router.push('/' + item.path), $store.state.blockId = item.block_id" style="width: 70%; cursor: pointer;" v-if="item.title">{{ item.title }}</p>
                        <p @click.stop="$router.push('/' + item.path), $store.state.blockId = item.block_id" style="text-decoration: underline; color: #00225F; font-size: 15px; cursor: pointer;">{{$t('ftl')}}</p>
                    </div>
                    <div v-else class="results">
                        <p @click.stop="$router.push('/'),  $store.state.blockId = item.block_id" style="width: 70%; cursor: pointer;" v-if="item.title">{{ item.title }}</p>
                        <p @click.stop="$router.push('/'),  $store.state.blockId = item.block_id" style="text-decoration: underline; color: #00225F; font-size: 15px; cursor: pointer;">{{$t('ftl')}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AllResultsPage',
    data() {
        return {
            allDates: []
        };
    },
    mounted() {
        if (this.$route.query.search) {
            const searchData = localStorage.getItem("searchResult");
            if (searchData) {
                try {
                    this.allDates = JSON.parse(searchData);
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                }
            }
        }
    },
    watch: {
        "$route.query"() {
            if (this.$route.query.search) {
                const searchData = localStorage.getItem("searchResult");
                if (searchData) {
                    try {
                        this.allDates = JSON.parse(searchData);
                    } catch (error) {
                        console.error("Error parsing JSON:", error);
                    }
                }
            }
        }
    }
}
</script>       
<style lang="scss" scoped>
    .allresults{
        min-height: 100vh;
        margin-top: 40px;
        margin-bottom: 40px;
        &__block{
            display: flex;
            flex-direction: column;
            gap: 20px;
            &__title{
                font-size: 36px;
                font-weight: 700;
                color: #00225F;
            }
            &__count{
                font-size: 20px;
                font-weight: 500;
            }
            &__res{
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }
    }
    .results{
        border: 1px solid rgba(130, 168, 157, 0.575);
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        border-radius: 15px;
        color: rgba(0, 0, 0, 0.486);
    }
</style>