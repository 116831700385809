<template> 
  <div>
    <Header  v-if="!$route.meta.isHeader "/> 
    <router-view class="main" @click="$store.state.openSearchModal = false"/> 
    <Footer v-if="!$route.meta.isFooter " /> 
  </div> 
</template> 
 
<script> 
import AOS from 'aos'; 
import 'aos/dist/aos.css';
import Footer from '@/components/layouts/Footer.vue'; 
import Header from '@/components/layouts/Header.vue'; 
 
export default { 
  components: { Header, Footer } ,
  mounted() {
    AOS.init();
  },
  mounted() {
    AOS.init();
  }
} 
 
</script> 
 
<style lang="scss"> 
#app { 
width: 100vw; 
min-height: 100vh; 
display: flex; 
flex-direction: column; 
align-items: stretch; 
} 
 
html,body,p { 
  overflow-x: hidden; 
  margin: 0 ; 
  padding: 0 ; 
} 
</style>